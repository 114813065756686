import React, { useEffect, useState } from 'react'
// import GetLandOwner from '../../../APIs/Owner/GetLandOwner'
import avtar from "../../../assets/images/avatar.png"
import DeleteLandOwner from "../../../APIs/Owner/DeleteLandOwner"
import GetLandOwnerByLandId from '../../../APIs/Owner/GetLandOwnerByLandId'
import GetLandOwnerFullDetailsByLandId from '../../../APIs/Owner/GetLandOwnerFullDetailsByLandId'
export default function OwnerShow(props) {
    let [ownerData, setOwnerData] = useState([])
    let [activeOwner, setActiveOwner] = useState(-1)
    let [totalLandPrice, setTotalLandPrice] = useState(0)
    let getData = async () => {
        let resp = await GetLandOwnerFullDetailsByLandId(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : "")
        setTotalLandPrice(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.price : 0)
        if (resp.status === 1 && resp.responseValue.length !== 0) {
            setOwnerData(resp.responseValue)
            props.setNoOfOwnerSaved(resp.responseValue.length)
            let allPercentage = resp.responseValue.map(value=>value.percentage)
            props.setTotalPercentage(allPercentage.reduce((oldVlaue, currentValue)=>{return oldVlaue + currentValue}))
            // console.log("per", )

        }
    }

    
    let deleteLand = async (id) => {
        let resp = await DeleteLandOwner(id)
        if (resp.status === 1) {
            getData()
        }
        else {

        }
    }

    useEffect(() => {
        if(props.change === -1)
        {
            setActiveOwner(-1)
        }
            getData()
    }, [props.change])
    return (
        <>
            {
                ownerData && ownerData.map((val, ind) => {
                    return (
                        <div className='d-flex flex-column gap-3  ps-2 pe-2 pb-2' >
                            <div className="d-flex flex-column flex-sm-row gap-2 justify-content-between align-items-center ps-5 pe-5 land-info-collapse" style={{backgroundColor: activeOwner === ind?"#00aff0":"", color:activeOwner === ind?"black":""}}>
                                <div className='d-flex flex-row gap-2'>
                                    <img src={avtar} className="float-start" alt="..." style={{ width: "40px", height: "40px", borderRadius: "20px" }} />
                                    <div className='d-flex flex-column'>
                                        <span className='land-collapse-text' style={{ color: activeOwner === ind?"black":""}}>{val.name}</span>
                                        <span className='land-collapse-text' style={{ color: activeOwner === ind?"black":"#737791", fontWeight: "500" }}>&#8377; {parseFloat((totalLandPrice*(val.percentage / 100))).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className='d-none d-sm-flex'>
                                    <div className='d-flex flex-column'>
                                        <span className='land-collapse-text' style={{ color: activeOwner === ind?"black":""}}>Percentage (%)</span>
                                        {/* <span className='land-collapse-text' style={{ color: "#151D48", fontWeight: "800", fontSize: "1.3rem" }}><i class="bi bi-currency-rupee fa-sm"></i>{val.price}</span> */}
                                        <span className='land-collapse-text' style={{ color:  activeOwner === ind?"black":"#151D48", fontWeight: "800", fontSize: "1.3rem" }}>{val.percentage} %</span>
                                    </div>
                                </div>
                                <div className='d-none d-md-flex gap-2'>
                                <i class="bi bi-pencil-square pointer" onClick={() => { props.handleEdit(val); setActiveOwner(ind) }}></i> <i class="bi bi-trash pointer" onClick={() => { deleteLand(val.id) }}></i>
                                </div>
                            </div>
                            
                        </div>
                    )
                })
            }


          
        </>
    )
}
